import type { MaybeRef, WritableComputedRef } from 'vue'
import type { OptionRentReviewTable } from '../types'
import type { IOptionsForm } from '@manager/components/Group/Custom/OptionExerciseDetailsTable/types'
import { convertToRentReviewTable } from '@manager/components/Group/Custom/RentReviewTable/utils'
import type { RepeaterItem } from '@manager'
import { IncreaseTableMethod } from '@register'

export const useRentReviewData = (
  initialValue: MaybeRef<OptionRentReviewTable[]>,
  formValue: WritableComputedRef<IOptionsForm>,
) => {
  const { items, filteredItems, add, update, remove, getById } =
    useRepeater<OptionRentReviewTable>(initialValue, {
      keepPreviousState: true,
    })

  const isEmpty = computed(() => filteredItems.value.length === 0)

  const expiryDate = computed(
    () => formValue.value.SupportFieldsGroup?.data?.[0]?.ExpiryDate,
  )
  const dayjs = useDayjs()

  const MAX_ITERATIONS = 200
  let totalIndexes = 0
  const previousOptionInitialRentIncreaseType = ref<
    IncreaseTableMethod | null | undefined
  >()

  debouncedWatch(
    [
      () => formValue.value.OptionsConfiguration?.data ?? [],
      () => formValue.value.OptionInitialRentIncreaseType,
    ],
    ([newConfigurations], [_, _previousOptionInitialRentIncreaseType]) => {
      if (!expiryDate.value) return

      previousOptionInitialRentIncreaseType.value =
        _previousOptionInitialRentIncreaseType

      let currentOption = 0
      let currentIndex = 0

      for (let index = 0; index < newConfigurations.length; index++) {
        if (currentIndex >= MAX_ITERATIONS) break // Break if total iterations reach the maximum limit

        const newConfig = newConfigurations[index]

        for (let n = 0; n < Number(newConfig.Number || 0); n++) {
          if (currentIndex >= MAX_ITERATIONS) break // Break if total iterations reach the maximum limit

          let currentReview = 0

          for (let y = 0; y < Number(newConfig.Years || 0); y++) {
            if (currentIndex >= MAX_ITERATIONS) break // Break if total iterations reach the maximum limit
            addOrUpdateItem(currentIndex, {
              Option: currentOption + 1,
              Review: currentReview + 1,
            })
            currentIndex++
            currentReview++
          }

          const Months = Number(newConfig.Months ?? 0)
          const Days = Number(newConfig.Days ?? 0)

          if (Months > 0 || Days > 0) {
            addOrUpdateItem(currentIndex, {
              Option: currentOption + 1,
              Review: currentReview + 1,
              Months,
              Days,
            })
            currentIndex++
          }

          currentOption++
        }
      }

      removeExtraItems(currentIndex)
      totalIndexes = currentIndex
    },
    { immediate: true, deep: true, debounce: 300 },
  )

  return {
    items,
    filteredItems: computed(() =>
      filteredItems.value.map(convertToRentReviewTable),
    ),
    add,
    update,
    remove,
    getById,
    isEmpty,
  }

  function addOrUpdateItem(
    index: number,
    {
      Option,
      Review,
      Months,
      Days,
    }: { Option: number; Review: number; Months?: number; Days?: number },
  ) {
    let item = items.value[index]
    const previousItem = items.value[index - 1]
    const previousEndDate = previousItem?.__endDate ?? expiryDate.value

    const newItem: OptionRentReviewTable = {
      Option,
      Date: dayjs(previousEndDate).add(1, 'day').toISOString(),
    } as OptionRentReviewTable

    if (Review === 1) {
      if (
        !item?.Method ||
        item.Method === formValue.value.OptionInitialRentIncreaseType ||
        item.Method === previousOptionInitialRentIncreaseType.value
      ) {
        newItem.Method = formValue.value.OptionInitialRentIncreaseType
      }
    }

    if (item) {
      update(item.__id, newItem)
      item.__deleted = false
    } else {
      item = newItem as RepeaterItem<OptionRentReviewTable>
      add(newItem)
    }

    Object.defineProperty(item, '__endDate', {
      value: (Months !== undefined && Days !== undefined
        ? dayjs(previousEndDate).add(Months, 'months').add(Days, 'days')
        : dayjs(previousEndDate).add(1, 'year')
      ).toISOString(),
      writable: true,
      enumerable: false,
    })
  }

  function removeExtraItems(currentNumber: number) {
    if (totalIndexes > currentNumber) {
      for (let i = totalIndexes - 1; i >= currentNumber; i--) {
        const item = items.value[i]
        if (item) {
          remove(item.__id)
        }
      }
    }
  }
}
